/* Popup background overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup box styling */
.popup {
  background: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  width: 1000px; /* Increase width for horizontal layout */
  max-width: 90%;
  text-align: left;
  position: relative;
}

/* Title styling */
.popup-title {
  margin: 0 0 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

/* Form content styling */
.popup-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.popup-row {
  display: flex;
  align-items: center;
  gap: 15px; /* Space between elements in a row */
}

.popup-row label {
  flex-basis: 100px; /* Consistent label width for alignment */
  color: #555;
  font-weight: 500;
}

/* Input and select styling */
.popup-input,
.popup-select {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s ease-in-out;
}

.popup-input:focus,
.popup-select:focus {
  border-color: #007bff;
  outline: none;
}

/* Action buttons */
.popup-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.popup-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease;
}

.popup-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Close button styling */
.popup-close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #888;
  cursor: pointer;
  transition: color 0.3s ease;
}

.popup-close:hover {
  color: #333;
} /* Day selector styling */
