.day-selector {
    display: flex;
    gap: 10px;
  }
  
  .day-box {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #555;
    background-color: #f0f0f0;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .day-box.selected {
    background-color: #007bff;
    color: #fff;
  }
  
  .day-box:hover {
    background-color: #007bff;
    color: #fff;
  }
  