/* Main container */
.Home {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    overflow: hidden;
  }
  
  /* Background video styling */
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
  }
  
  /* Content styling */
  .Home_div {
    align-items: center;
    background-color: rgb(255, 255, 255); /* Translucent overlay */
    padding: 2%;
    z-index: 1;
  }
  
  .Home_h1 {
    color: black;
  }
  
  .Home_h1_links {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Home_h1_links_shoonya_img {
    width: 50%;
  }
  
  .Home_h1_links_kotak_img {
    width: 17%;
  }
  
  .Home_h1_links_shoonya {
    flex: 1;
  }
  
  .Home_h1_links_kotak {
    flex: 1;
  }
  