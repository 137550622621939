.strategy-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.strategy-table th,
.strategy-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.strategy-table th {
  background-color: #4caf50;
  color: white;
}

.strategy-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.strategy-table tr:hover {
  background-color: #ddd;
}
